import {
    PLAY_PAUSE_AUDIO,
    SET_AUDIO_SOURCE,
    SET_AUDIO_PROGRESS,
    SET_AUDIO_SOURCE_AND_PLAY
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case PLAY_PAUSE_AUDIO:
            return {
                ...state,
                audioPlaying: action.payload
            };


        case SET_AUDIO_SOURCE_AND_PLAY:
            return {
                ...state,
                audioSrc: action.payload,
                audioPlaying: true
            };

        case SET_AUDIO_SOURCE:
            return {
                ...state,
                audioSrc: action.payload
            };

        case SET_AUDIO_PROGRESS:
            return {
                ...state,
                audioProgress: action.payload
            };

        default:
            return state;
    }
};