import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';


class InvertDeltaPlugin extends ScrollbarPlugin {
  static pluginName = 'invertDelta';
  static defaultOptions = {
    events: []
  };

  transformDelta(delta, fromEvent) {
    if (this.shouldInvertDelta(fromEvent)) {
      return {
        x: delta.y,
        y: delta.x
      };
    }

    return delta;
  }

  shouldInvertDelta(fromEvent) {
    return this.options.events.some(rule => fromEvent.type.match(rule));
  }
}


/* Register Plugin */

Scrollbar.use(InvertDeltaPlugin, OverscrollPlugin);

/*
  Options Plugin
*/

const overscrollOptions = {
  effect: 'bounce',
  damping: 0.2,
  maxOverscroll: 300,
}

const invertDeltaOptions = {
  events: [/wheel/]
}

const mainOptions = {
  damping: 0.03,
  thumbMinSize: 0,
  renderByPixels: true,
  alwaysShowTracks: false,
  continuousScrolling: true,
  plugins: {
    overscroll: overscrollOptions,
    invertDelta: invertDeltaOptions,
  }
};

const SmoothScroll = element =>
  Scrollbar.init(document.querySelector(element), mainOptions);

export { SmoothScroll }
export default SmoothScroll;


