import {
    SET_FILTER
} from '../types';

export default (state, action) => {
    switch (action.type) {

        case SET_FILTER:
            return {
                ...state,
                filter: action.payload
            };

        default:
            return state;
    }
};