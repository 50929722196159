import React, { useReducer, useEffect } from 'react';
import ProgressContext from './progressContext';
import ProgresReducer from './progressReducer';

import config from '../../config.js';
import AppHelper from '../../helpers/App';


import {
    SET_CONTENT_VISITED,
    RESET_CONTENT_VISITED
} from '../types';

const ProgressState = props => {

    //const localState = JSON.parse(localStorage.getItem("app_state"));

    const initialState = {
        visited: [],
        progress: 0,
        lastProgress: 0,
    }

    const [state, dispatch] = useReducer(ProgresReducer, initialState); //localState || 

    useEffect(() => {
        //localStorage.setItem("kiosk_state", JSON.stringify(state));
    }, [state]);


    const contentVisited = (contentId, contentsCount) => {
        setTimeout(() => {
            dispatch({
                type: SET_CONTENT_VISITED,
                payload: {
                    id: contentId,
                    count: contentsCount
                }
            });
        }, 1000);

    }

    const contentsVisitedReset = () => {
        dispatch({
            type: RESET_CONTENT_VISITED,
            payload: true
        });
    }





    return <ProgressContext.Provider
        value={{
            progress: state.progress,
            visited: state.visited,
            lastProgress: state.lastProgress,
            contentVisited: contentVisited,
            contentsVisitedReset: contentsVisitedReset

        }}>
        {props.children}
    </ProgressContext.Provider>
};

export default ProgressState;