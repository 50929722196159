import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import filterContext from '../../context/filter/filterContext';

import AppHelper from '../../helpers/App';
import JSONHelper from '../../helpers/JSON';

const ThemesList = ({ themes, addAllLink, navigate }) => {

    const filterState = useContext(filterContext);

    const setFilter = (event, theme) => {
        //appContext.reset();
        filterState.setFilter(theme);
        if (navigate) {

        } else {
            event.preventDefault();
        }

    }

    return (
        <React.Fragment>
            {themes &&
                <nav>
                    <ul>
                        {addAllLink && <li className={filterState.filter === 'all' ? 'active' : ''}><NavLink to={'#'} onClick={(event) => setFilter(event, 'all')}>All</NavLink></li>}
                        {themes.map((t, i) => {
                            const name = JSONHelper.getSingleTextValue(t, 'name');
                            const slug = AppHelper.convertToSlug(name);
                            return (<li key={i} className={filterState.filter === i ? 'active' : ''}><NavLink to={'/contents'} onClick={(event) => setFilter(event, i)}>{name}</NavLink></li>);
                        })}
                    </ul>
                </nav>
            }
        </React.Fragment>
    )
}
export default ThemesList; 