import React, { useReducer } from 'react';
import AudioContext from './audioContext';
import AudioReducer from './audioReducer';

import config from '../../config.js';


import {
    PLAY_PAUSE_AUDIO,
    SET_AUDIO_SOURCE,
    SET_AUDIO_PROGRESS,
    SET_AUDIO_SOURCE_AND_PLAY,
    SEEK_AUDIO
} from '../types';

const AudioState = props => {
    const initialState = {
        audioPlaying: false,
        audioSrc: false,
        audioProgress: 0
    }

    const [state, dispatch] = useReducer(AudioReducer, initialState);

    // set audio play tyrue false
    const playAudio = play => {
        dispatch({
            type: PLAY_PAUSE_AUDIO,
            payload: play
        });
    };

    // seek
    const seekAudio = to => {
        dispatch({
            type: SEEK_AUDIO,
            payload: to
        });
    };

    // set audio src
    const setAudioSource = src => {
        dispatch({
            type: SET_AUDIO_SOURCE,
            payload: src
        });
    };

    const setAudioSourceAndPlay = src => {
        dispatch({
            type: SET_AUDIO_SOURCE_AND_PLAY,
            payload: src
        });
    };



    // set progress
    const setAudioProgress = progress => {
        dispatch({
            type: SET_AUDIO_PROGRESS,
            payload: progress
        });
    };

    return <AudioContext.Provider
        value={{
            playAudio: playAudio,
            audioPlaying: state.audioPlaying,
            setAudioSource: setAudioSource,
            setAudioSourceAndPlay: setAudioSourceAndPlay,
            audioSrc: state.audioSrc,
            setAudioProgress: setAudioProgress,
            audioProgress: state.audioProgress,
            seekAudio: seekAudio

        }}>
        {props.children}
    </AudioContext.Provider>
};

export default AudioState;