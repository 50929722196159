import React from 'react';
import JSONHelper from '../../helpers/JSON';
import BackgroundImage from './BackgroundImage';
import { NavLink } from 'react-router-dom';

const ContentListItem = ({ json }) => {


    const title = JSONHelper.getSingleTextValue(json, 'title');
    const subtitle = JSONHelper.getSingleTextValue(json, 'subtitle');
    const quote = JSONHelper.getSingleTextValue(json, 'quote');
    var icon = json.attractorIcon;
    if (icon === 360) {
        icon = 'c360';
    }
    if (!icon) {
        icon = 'image';
    }


    return (
        <NavLink to={'/content/' + json.url} className={icon}>
            <BackgroundImage icon={icon} json={JSONHelper.getValue(json, 'multimedia', false)} />
            <h3>{quote ? quote : title}</h3>
            <h4>{subtitle}</h4>
        </NavLink>
    )
}
export default ContentListItem;