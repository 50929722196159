import React, { memo, useEffect } from 'react';
import config from '../../../config.js';
import ContentDetails from '../ContentDetails';

import OpenSeadragon from 'openseadragon';

import zoomin_rest from '../../../images/seadragon/zoomin_rest.png';
import zoomin_grouphover from '../../../images/seadragon/zoomin_grouphover.png';
import zoomin_hover from '../../../images/seadragon/zoomin_hover.png';
import zoomin_pressed from '../../../images/seadragon/zoomin_pressed.png';

import zoomout_rest from '../../../images/seadragon/zoomout_rest.png';
import zoomout_grouphover from '../../../images/seadragon/zoomout_grouphover.png';
import zoomout_hover from '../../../images/seadragon/zoomout_hover.png';
import zoomout_pressed from '../../../images/seadragon/zoomout_pressed.png';

import home_rest from '../../../images/seadragon/home_rest.png';
import home_grouphover from '../../../images/seadragon/home_grouphover.png';
import home_hover from '../../../images/seadragon/home_hover.png';
import home_pressed from '../../../images/seadragon/home_pressed.png';

import fullpage_rest from '../../../images/seadragon/fullpage_rest.png';
import fullpage_grouphover from '../../../images/seadragon/fullpage_grouphover.png';
import fullpage_hover from '../../../images/seadragon/fullpage_hover.png';
import fullpage_pressed from '../../../images/seadragon/fullpage_pressed.png';

const ZoomImageType = ({ detail, click, contentJson, media, onDetailOpen }) => {

    //const image = 'https://rafm.tours/ciim-iip/iipsrv.fcgi?Deepzoom=2/260/27/807/AV_SRH_H_SC1_Reach_RGB_2500x1875_pixels.ptif.dzi';
    //const image = 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flower_jtca001.jpg';
    const id = 'ocd-viewer-' + media.fileId;

    useEffect(() => {

        const source = config.getZoomImageURL() + media.processed.zoom.location + '.dzi'

        if (detail) {
            const viewer = OpenSeadragon({
                id: id,
                tileSources: source,
                /*
                tileSources: {
                    type: 'legacy-image-pyramid',
                    levels: [{ url: image, height: 2240, width: 1680 }]
                },*/
                homeFillsViewer: true,
                showFullPageControl: false,
                prefixUrl: "",
                navImages: {
                    zoomIn: {
                        REST: zoomin_rest,
                        GROUP: zoomin_grouphover,
                        HOVER: zoomin_hover,
                        DOWN: zoomin_pressed
                    },
                    zoomOut: {
                        REST: zoomout_rest,
                        GROUP: zoomout_grouphover,
                        HOVER: zoomout_hover,
                        DOWN: zoomout_pressed
                    },
                    home: {
                        REST: home_rest,
                        GROUP: home_grouphover,
                        HOVER: home_hover,
                        DOWN: home_pressed
                    },
                    fullpage: {
                        REST: fullpage_rest,
                        GROUP: fullpage_grouphover,
                        HOVER: fullpage_hover,
                        DOWN: fullpage_pressed
                    },
                },
            });
        }

    }, []);

    return (
        <ContentDetails contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} bottomInfo={true}>
            <div className="detail-media-wrap">
                <div id={id} className="content-zoom"></div>
            </div>
        </ContentDetails>
    );
};
export default memo(ZoomImageType);
