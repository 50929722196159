
import config from '../config';

export default class JSONHelper {

    static getSource = (data) => {
        if (data && data.hits) {
            return data.hits.hits[0]._source;
        }
        return false;
    }
    static getHits = (data) => {
        if (data && data.hits) {
            return data.hits.hits;
        }
        return [];
    }

    static getJSON = (data, ident) => {
        if (data[ident]) {
            return data[ident].hits.hits;
        }
        return false;
    }

    static getSingleJSON = (data, ident) => {
        if (data[ident]) {
            return this.getContent(data[ident]);
        }
        return false;
    }

    static getContent = (json) => {
        return json.hits.hits[0]._source;
    }

    static getSingleTextValue = (json, property) => {
        if (json[property] && json[property][0] && json[property][0].value) {
            return json[property][0].value;
        }
        return '';
    }

    static getValue = (json, property, ifEmpty) => {
        if (json[property]) {
            return json[property];
        }
        return ifEmpty;
    }

    static getPrimaryImage = (json) => {

        let i = json.filter(f => f.primary === true);
        return i.length ? i[0] : false;
    }

    static getMediaTitleByType = (json, type) => {
        if (!json.source) { return ''; }
        if (!json.source.title) { return ''; }

        let t = json.source.title.filter(tt => tt.type === type);
        if (t.length) {
            if (t[0].value) {
                return t[0].value;
            }
        }
        return '';
    }

    static getImageUrl = (json, size) => {
        if (size) {
            if (json.processed && json.processed[size]) {
                let imgData = json.processed[size];
                if (imgData.location_is_relative) {
                    return config.mediaURL + imgData.location;
                } else {
                    return imgData.location;
                }
            } else {
                return json.source.location;
            }

        } else {
            return json.source.location;
        }
        return '';
    }

    static getMediaByMediaType = (multimedia, type) => {
        let m = multimedia.filter(i => i.mediaType === type);
        return m.length ? m[0] : false;
    }

    static getAdminId = (json) => {
        if (json.admin && json.admin.id) {
            return json.admin.id;
        }
        return false;
    }

    static getContentType = (json) => {
        if (json.type && json.type.base) {
            return json.type.base;
        }
        return false;
    }
}