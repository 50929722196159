import React, { useReducer, useEffect } from 'react';
import AppContext from './appContext';
import AppReducer from './appReducer';

import config from '../../config.js';
import ElasticSearch from '../../data/ElasticSearch.js';
import AppHelper from '../../helpers/App';


import {
    SET_LOADING,
    SET_SLEEPING,
    RESET,
    SET_WAYPOINT,
    SET_CONTENTS
} from '../types';

const AppState = props => {

    //const localState = JSON.parse(localStorage.getItem("app_state"));

    const initialState = {
        transition: 'fade',
        loaded: false,
        sleeping: true,
        waypoint: false,
        contents: false,
        filter: 'all'
    }

    const [state, dispatch] = useReducer(AppReducer, initialState); //localState || 

    useEffect(() => {
        localStorage.setItem("kiosk_state", JSON.stringify(state));
    }, [state]);

    const getWaypoint = async () => {
        const waypointId = config.waypointId;

        setLoading();
        //const res = await fetch(`${config.jsonsUrl}/waypoints.json`);
        //const data = await res.json();

        const query = `{
                "query":{ 
                "bool":{
                    "must":[
                        {
                        "terms":{
                            "admin.id": ["${waypointId}"]
                        }
                        }
                    ],
                    "must_not":[],
                    "should":[]
                } 
                },
                "from":0,
                "size":1,
                "sort":[],
                "aggs":{}
            }`;

        const data = await ElasticSearch(query);

        dispatch({
            type: SET_WAYPOINT,
            payload: {
                id: waypointId,
                data: data
            }
        });

    };


    const getContents = async () => {

        const waypointId = state.waypoint.admin.id;
        const contents = AppHelper.getWaypointContents(state.waypoint);

        var contedntIds = [];
        contents.forEach((c, index) => {
            if (c.admin) {
                contedntIds.push(c.admin.id);
            }
        });

        if (!state.contents[waypointId]) {// already in ?


            const query = `{
                "query":{ 
                "bool":{
                    "must":[
                        {
                        "terms":{
                            "admin.id": ["${contedntIds.join('","')}"]
                        }
                        }
                    ],
                    "must_not":[],
                    "should":[]
                } 
                },
                "from":0,
                "size":100,
                "sort":[],
                "aggs":{}
            }`;

            const data = await ElasticSearch(query);

            dispatch({
                type: SET_CONTENTS,
                payload: {
                    id: waypointId,
                    data: data
                }
            });

        }
    };

    // start
    const start = () => dispatch({ type: SET_SLEEPING, payload: false });
    const reset = () => dispatch({ type: SET_SLEEPING, payload: true });


    // set loading
    const setLoading = () => dispatch({ type: SET_LOADING });




    return <AppContext.Provider
        value={{
            loaded: state.loaded,
            sleeping: state.sleeping,
            start: start,
            reset: reset,
            getWaypoint: getWaypoint,
            waypoint: state.waypoint,
            getContents: getContents,
            contents: state.contents

        }}>
        {props.children}
    </AppContext.Provider>
};

export default AppState;