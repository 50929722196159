
const config = {
    mode: process.env.REACT_APP_API_URL,
    mediaURL: 'https://rafm.tours/ciim-media/',
    mediaKioskURL: '/ciim-internal-media/',
    elasticSearchLive: 'https://rafm.tours/ciim-search',
    elasticSearchTest: 'http://rafs.code8.cz/public/api/mobile/search', // '',http://172.16.0.84/es-ext/_search
    zoomImageURL: 'https://rafm.tours/ciim-iip/iipsrv.fcgi?Deepzoom=/var/www/ciim-ecosystem/zooms/',
    kioskZoomImageURL: '/ciim-internal-iip/iipsrv.fcgi?Deepzoom=/var/www/ciim-ecosystem/zooms/',

    waypointId: document.getElementById('root').dataset.id,
    rootDir: document.getElementById('root').dataset.dir,

    jsonsUrl: '/json/',
    loginEndpoint: 'http://rafs.code8.cz/public/api/mobile/login',
    userData: {
        email: 'ondra@code8.cz',
        password: 'OndraFurier8!',
    },
    getMediaURL() {
        return this.mediaURL
    },
    getZoomImageURL() {
        return this.zoomImageURL
    },
    getSearchURL() {
        return (this.isLive() ? this.elasticSearchLive : this.elasticSearchTest);
    },
    isLive() {
        return this.mode === 'LIVE';
    }
};

module.exports = config;
