// core / app 
export const SET_LOADING = 'SET_LOADING';
export const SET_WAYPOINT = 'SET_WAYPOINTS';
export const SET_CONTENTS = 'SET_CONTENTS';
export const SET_SLEEPING = 'SET_SLEEPING';
export const RESET = 'RESET';
export const SET_FILTER = 'SET_FILTER';

//gallery
export const SET_SLIDE = 'SET_SLIDE';

// progress  
export const SET_CONTENT_VISITED = 'SET_CONTENT_VISITED';
export const RESET_CONTENT_VISITED = 'RESET_CONTENT_VISITED';


// audio
export const PLAY_PAUSE_AUDIO = 'PLAY_PAUSE_AUDIO';
export const SET_AUDIO_SOURCE = 'SET_AUDIO_SOURCE';
export const SET_AUDIO_SOURCE_AND_PLAY = 'SET_AUDIO_SOURCE_AND_PLAY';
export const SET_AUDIO_PROGRESS = 'SET_AUDIO_PROGRESS';
export const SEEK_AUDIO = 'SEEK_AUDIO';