import React, { useState, useEffect, useRef } from 'react';
import Button from './Button';
import JSONHelper from '../../helpers/JSON';
import Textnl2p from './Textnl2p';
import { SmoothScroll } from '../../helpers/SmoothScroll';

const ContentDetails = ({ detail, onDetailOpen, contentJson, media, bottomInfo = true, ...props }) => {

    const [showDetail, setShowDetail] = useState(false);
    var scrollBar = useRef(false);
    var timer = useRef(false);

    const [nextArrowVisible, setNextArrowVisible] = useState(false);
    const [prevArrowVisible, setPrevArrowVisible] = useState(false);

    const manageDetail = (event) => {
        setShowDetail(!showDetail);
        if (onDetailOpen) {
            onDetailOpen(event);
        }

        if (!showDetail) {
            setTimeout(() => {
                setScrollPane();
            }, 0);
        }


        event.stopPropagation();
        event.preventDefault();
    }

    const setScrollPane = () => {
        scrollBar.current.update();
        if (scrollBar.current.size.content.height > scrollBar.current.size.container.height) {
            setNextArrowVisible(true);
        } else {
            setNextArrowVisible(false);
        }
    }

    const scrollNext = (event) => {
        scrollBar.current.scrollTo(0, scrollBar.current.offset.y + 200, 500);
    }

    const scrollPrev = (event) => {
        scrollBar.current.scrollTo(0, scrollBar.current.offset.y - 200, 500);
    }

    let legal = ''
    let title = ''

    if ('legal' in media.source) {
        legal = media.source.legal.credit_line;
    }
    if (media.source.title[0].value !== 'undefined') {
        title = media.source.title[0].value;
    }

    const contentTitle = JSONHelper.getSingleTextValue(contentJson, 'title');
    const contentSubtitle = JSONHelper.getSingleTextValue(contentJson, 'subtitle');
    const contentQuote = JSONHelper.getSingleTextValue(contentJson, 'quote');
    const contentText = JSONHelper.getSingleTextValue(contentJson, 'text');

    useEffect(() => {

        scrollBar.current = SmoothScroll(`#text-contents-${media.admin.id}`);
        scrollBar.current.addListener((status) => {
            if (timer.current) {
                window.cancelAnimationFrame(timer.current);
            }

            // Setup the new requestAnimationFrame()
            timer.current = window.requestAnimationFrame(function () {

                if (status.offset.y >= status.limit.y) {
                    setNextArrowVisible(false);
                } else if (status.offset.y <= 0) {
                    setPrevArrowVisible(false);
                } else {
                    setNextArrowVisible(true);
                    setPrevArrowVisible(true);
                }

            });
        });

    }, [contentJson]);


    return (
        <div className="media-wrap" >
            <div className={`full-info ${showDetail ? 'visible' : 'hidden'}`}>
                <div className="media-col">
                    {props.children}

                    {title && <caption>{title}</caption>}
                    {legal && <caption>{legal}</caption>}

                </div>
                <div className="text-col">

                    <div id={`text-contents-${media.admin.id}`} className={`text-contents dragscroll text ${prevArrowVisible ? 'has-prev' : ''} ${nextArrowVisible ? 'has-next' : ''}`}>
                        <h1 className="h3">{contentQuote ? contentQuote : contentTitle}</h1>
                        <Textnl2p text={contentText} />
                    </div>

                    <Button to={'#'} onClick={scrollPrev} className={`btn svg-btn text-prev ${prevArrowVisible ? '' : 'disabled'}`} type="prev-text" text="Previous" />
                    <Button to={'#'} onClick={scrollNext} className={`btn svg-btn text-next ${nextArrowVisible ? '' : 'disabled'}`} type="next-text" text="Next" />


                </div>

                <Button to={'#'} onClick={manageDetail} className="btn svg-btn back" type="back" text="Close" />
            </div>


            <Button to={'#'} onClick={manageDetail} className={`btn svg-btn show-info `} type="info" text="Show details" />

            {bottomInfo && title && <div className="bottom-info">
                {title && <div className="textOverflow">{title}</div>}
            </div>
            }
        </div >


    );
};
export default ContentDetails; 