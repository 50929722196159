
import React from 'react';
import JSONHelper from '../../helpers/JSON';
import Image from './Image';

const BackgroundImage = ({ json, clickFn, height, icon }) => {

    height = height ? height + "px" : false;

    var imgData = false;
    var imgUrl = '';
    var cls = 'landscape';

    // get primary ?
    if (json && json[0]) {
        imgData = JSONHelper.getPrimaryImage(json);
        imgData = imgData ? imgData : json[0];
    }

    if (imgData) {
        imgUrl = JSONHelper.getImageUrl(imgData, "xlarge");
        if (imgData.processed) {
            let dimensions = imgData.processed.xlarge.measurements.dimensions; // 0 height 1 width 
            if (dimensions[0].value > dimensions[1].value) {
                cls = 'portrait';
            }
        }
    }

    console.log(icon);

    return json ? (
        <figure className={`bgi ${cls}`} style={{ backgroundImage: "url(" + imgUrl + ")", height: height }} onClick={(event) => clickFn ? clickFn(event) : false} onKeyDown={(event) => clickFn ? clickFn(event) : false}>
            <Image json={imgData} size="xlarge" />
            {icon && <i className={icon}></i>}
        </figure>
    ) : '';
};
export default BackgroundImage;

