import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../context/app/appContext';
import filterContext from '../context/filter/filterContext';
import AudioContext from '../context/audio/audioContext';
import ProgressContext from '../context/progress/progressContext';

import Layout from '../layout/Layout';
import ThemesList from './components/ThemesList';
import ContentsList from './components/ContentsList';
import Button from './components/Button';
import Gallery from './components/Gallery';
import SeekBar from './components/SeekBar';


import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';

const Content = ({ location, history }) => {

    const appContext = useContext(AppContext);
    const audioContext = useContext(AudioContext);
    const progressContext = useContext(ProgressContext);

    const [showAudioInfo, setShowAudioInfo] = useState(false);

    const [contentJson, setContentJson] = useState(false);
    const [audioContent, setAudioContent] = useState(false);
    const contentUrl = AppHelper.getSecondUrlFromLocation(location);

    // audio  

    const stopAudio = () => {
        audioContext.playAudio(false);
        audioContext.setAudioSource('');
        audioContext.setAudioProgress(0);
    };

    const playAudio = () => {
        audioContext.setAudioSource(audioContent.source.location);
        //audioContext.playAudio(true);
    };

    const manageAudioButton = (event) => {
        //playAudio();
        manageAudioInfo(event);
    }

    const manageAudioInfo = (event) => {
        setShowAudioInfo(!showAudioInfo);
        event.stopPropagation();
        event.preventDefault();
    }

    // seek bar    
    const manageSeekBar = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const el = document.getElementById('audio-seekbar');
        const rect = el.getBoundingClientRect();
        const totalWidth = el.clientWidth;
        const left = (event.clientX - rect.left);
        const percentage = (left / totalWidth);

        const player = document.getElementById('audio-player');
        const seekTo = player.duration * percentage;
        player.currentTime = Math.round(seekTo);
    }

    useEffect(() => {
        let content = AppHelper.getContentByUrl(appContext.contents, contentUrl);
        setContentJson(content);
        setAudioContent(JSONHelper.getMediaByMediaType(content.multimedia, 'audio'));
        // log progress
        progressContext.contentVisited(content.admin.id, appContext.contents.length);

        return function () {
            stopAudio();
        }
    }, []);


    useEffect(() => {
        if (audioContent && audioContent.source) {
            playAudio();
        }
    }, [audioContent]);

    useEffect(() => {
        var abars = document.getElementsByClassName("audio-progress");
        var i;
        for (i = 0; i < abars.length; i++) {
            abars[i].style.setProperty('--aperc', audioContext.audioProgress);
            abars[i].style.setProperty('--vpend', "calc(var(--r) * var(--pi2) * var(--aperc) / 100)");
            abars[i].style.setProperty('--vprest', "calc(var(--r) * var(--pi2) * (100 - var(--aperc)) / 100)");
        }

    }, [audioContext.audioProgress]);
    // 
    return (
        <Layout>
            {contentJson &&
                <main className={`content`} role="main">
                    <div className="wrap white-text">
                        <Gallery contentJson={contentJson} waypointJson={appContext.waypointJson} />
                        <Button to={'/contents'} className="btn svg-btn back" type="back" text="Back to list" />

                        {audioContent && audioContent.source &&
                            <Button to={'#'} onClick={manageAudioButton} className={`btn svg-btn play-audio audio-progress ${audioContext.audioPlaying ? 'playing' : ''}`} type="audio" text="Play audio" />
                        }
                        {audioContent && audioContent.source &&
                            <div className={`overlay-screen ${showAudioInfo ? "visible" : "hidden"}`} onClick={(event) => manageAudioInfo(event)}>
                                <div className="content">
                                    <div className="inner">
                                        <Button to={'#'} onClick={manageAudioInfo} className={`btn svg-btn info-audio audio-progress`} type="audio" text="Play audio" />
                                        <p>{JSONHelper.getSingleTextValue(audioContent.source, 'title')}</p>
                                        {audioContent.source.legal && <p>{audioContent.source.legal.credit_line}</p>}

                                        <SeekBar id="audio-seekbar" onClick={manageSeekBar} progress={audioContext.audioProgress} ident="audio" />

                                    </div>
                                </div>
                            </div>
                        }


                    </div>
                </main>
            }
        </Layout>
    )
}
export default Content;
