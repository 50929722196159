
import AppHelper from '../../helpers/App';

import {
    SET_CONTENT_VISITED,
    RESET_CONTENT_VISITED
} from '../types';

export default (state, action) => {
    switch (action.type) {

        case SET_CONTENT_VISITED:

            let contentId = action.payload.id;
            // remove if exists and add to the end
            let visited = [...state.visited].filter(item => item !== contentId);
            visited.push(contentId);

            return {
                ...state,
                visited: visited,
                lastProgress: state.progress,
                progress: Math.round(100 / action.payload.count * visited.length)
            };

        case RESET_CONTENT_VISITED:
            return {
                ...state,
                visited: [],
                lastProgress: 0,
                progress: 0
            };

        default:
            return state;
    }
};