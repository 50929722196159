import React, { useEffect, useRef, useContext } from 'react';

import AudioContext from '../../../context/audio/audioContext';

const AudioPlayer = () => {

    const audioContext = useContext(AudioContext);

    const audioEl = useRef(null);

    useEffect(() => {
        audioContext.audioPlaying ? audioEl.current.play() : audioEl.current.pause();
    }, [audioContext.audioPlaying]);

    useEffect(() => {

        if (audioContext.audioSrc) {
            //audioEl.current.pause();
            var source = document.getElementById('src_mp3');
            source.src = audioContext.audioSrc;

            audioEl.current.load(); //call this to just preload the audio without playing
            audioEl.current.play(); //call this to play the song right away

        } else {
            audioEl.current.pause();
        }

    }, [audioContext.audioSrc]);

    const timeUpdate = () => {
        audioContext.setAudioProgress(
            Number(audioEl.current.currentTime / audioEl.current.duration * 100).toFixed(4)
        );
    };

    return (
        <audio ref={audioEl} onTimeUpdate={() => timeUpdate()} id="audio-player" className="audio-player" loop controls>
            {audioContext.audioSrc && <source id="src_mp3" type="audio/mp3" src="" />}
        </audio>
    );
};
export default AudioPlayer;