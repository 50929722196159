import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../context/app/appContext';
import filterContext from '../context/filter/filterContext';
import ProgressContext from '../context/progress/progressContext';

import Layout from '../layout/Layout';
import ThemesList from './components/ThemesList';
import ContentsList from './components/ContentsList';
import Button from './components/Button';

import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';

const Contents = (props) => {

    const appContext = useContext(AppContext);
    const filterState = useContext(filterContext);
    const progressContext = useContext(ProgressContext);
    const [themes, setThemes] = useState([]);




    useEffect(() => {

        var vbar = document.getElementsByClassName("visited-progress");

        vbar[0].style.setProperty('--perc', progressContext.lastProgress);

        setThemes(AppHelper.getThemes(appContext.waypoint));
        setTimeout(() => {
            vbar[0].classList.add('animate');
            vbar[0].style.setProperty('--perc', progressContext.progress);
            vbar[0].style.setProperty('--pend', "calc(var(--r) * var(--pi2) * var(--perc) / 100)");
            vbar[0].style.setProperty('--prest', "calc(var(--r) * var(--pi2) * (100 - var(--perc)) / 100)");

            //document.documentElement.style.setProperty('--perc', progressContext.progress);
            //document.documentElement.style.setProperty('--pend', "calc(var(--r) * var(--pi2) * var(--perc) / 100)");
            //document.documentElement.style.setProperty('--prest', "calc(var(--r) * var(--pi2) * (100 - var(--perc)) / 100)");

        }, 1000);

        localStorage.setItem("kiosk_plays", "1");

    }, []);

    return (
        <Layout>
            <main className={`contents`} role="main">
                <div className="wrap white-text">
                    <h2>{JSONHelper.getSingleTextValue(appContext.waypoint, 'title').toProperCase()}</h2>

                    <Button to={'/'} className="btn svg-btn home visited-progress" type="home" text="Back to homepage" />

                    <ThemesList themes={themes} addAllLink={true} />

                    {appContext.contents && <ContentsList contents={appContext.contents} filter={filterState.filter} />}

                </div>
            </main>
        </Layout>
    )
}
export default Contents;
