import React, { useRef, useEffect, useContext, useState, memo } from 'react';

import AudioContext from '../../../context/audio/audioContext';

import videojs from 'video.js';
import style from 'video.js/dist/video-js.css';
import Button from '../Button';
import ContentDetails from '../ContentDetails';

import config from '../../../config.js';
import JSONHelper from '../../../helpers/JSON';
import SeekBar from '../SeekBar';

const VideoType = ({ detail, type, contentJson, media, onDetailOpen, onActivation }) => {

    const audioContext = useContext(AudioContext);

    const videoEl = useRef(null);
    const [showVideoInfo, setShowVideoInfo] = useState(false);
    const [videoProgress, setVideoProgress] = useState(0);
    const mutedBool = media.mutedSound;

    const manageVideoInfo = (event) => {
        setShowVideoInfo(!showVideoInfo);
        onDetailOpen(event);
        event.preventDefault();
    }

    const manageVideoButton = (event) => {
        manageVideoInfo(event);
    }


    /*
    const timeUpdate = () => {
        audioContext.setAudioProgress(
            Number(audioEl.current.currentTime / audioEl.current.duration * 100).toFixed(4)
        );
    }; 
    */

    useEffect(() => {

        const trackSrc = media.processed.transcript ? config.getMediaURL() + media.processed.transcript.location : null;
        var videoSource = media.processed.mobile.location;
        if (window.innerWidth >= 1024) {
            videoSource = media.processed.desktop1.location
        }
        videoSource = config.getMediaURL() + videoSource;

        const videoJsOptions = {
            autoplay: false,
            controls: false,
            loop: false,
            height: window.innerHeight * 0.9,
            width: window.innerWidth * 0.9,
            bigPlayButton: true,
            html5: {
                nativeTextTracks: true
            },
            muted: mutedBool,
            sources: [{
                src: videoSource,
                type: 'video/mp4'
            }],
        };

        var player = videojs(videoEl.current, videoJsOptions, function onReady() {
            this.el_.querySelector("video").setAttribute('crossorigin', 'anonymous')
            this.on('loadedmetadata', function () {

                if (trackSrc) {
                    let track = document.createElement("track");
                    track.kind = "captions";
                    track.label = "English";
                    // track.srclang = "en";
                    track.default = true;
                    track.src = trackSrc;
                    track.mode = 'showing';
                    this.addRemoteTextTrack(track, true)
                }

            })
            this.on('timeupdate', function () {

                let progress = Number(this.currentTime() / this.duration() * 100).toFixed(4)
                setVideoProgress(progress);

                var vbars = document.getElementsByClassName("video-progress");
                var i;
                for (i = 0; i < vbars.length; i++) {
                    vbars[i].style.setProperty('--vperc', progress);
                    vbars[i].style.setProperty('--vpend', "calc(var(--r) * var(--pi2) * var(--vperc) / 100)");
                    vbars[i].style.setProperty('--vprest', "calc(var(--r) * var(--pi2) * (100 - var(--vperc)) / 100)");
                }

            })

            this.on('ended', function () {
                //this.posterImage.show();
                //this.bigPlayButton.show();
                this.currentTime(0);
            })


            // Pause audio on video play
            this.on('play', function () {
                // pause audio
                if (!mutedBool) {
                    audioContext.playAudio(false);
                }
            })
        }).addClass('raf-video-skin');

        return () => {
            if (player) {
                player.dispose()
            }
        };


    }, []);

    // seek bar    
    const manageSeekBar = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const el = document.getElementById('video-seekbar');
        const rect = el.getBoundingClientRect();
        const totalWidth = el.clientWidth;
        const left = (event.clientX - rect.left);
        const percentage = (left / totalWidth);

        let player = videojs(videoEl.current);
        const seekTo = player.duration() * percentage;
        player.currentTime(Math.round(seekTo));
    }

    return (
        <React.Fragment>
            <ContentDetails contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} bottomInfo={false}>
                <div className="detail-media-wrap">
                    <div className="video-container" data-vjs-player >
                        <video ref={videoEl} className="video-js" onClick={(event) => alert(event)} />
                        <div onClick={(event) => onActivation(event)} className="activity"></div>
                    </div>
                </div>
            </ContentDetails>

            <Button to={'#'} onClick={manageVideoButton} className={`btn svg-btn play-video playing video-progress`} type="video" text="Play video" />
            {mutedBool && <Button to={'#'} className={`btn svg-btn no-sound `} type="no-sound" text="No sound" />}


            <div className={`overlay-screen ${showVideoInfo ? "visible" : "hidden"}`} onClick={(event) => manageVideoInfo(event)}>
                <div className="content">
                    <div className="inner">
                        <Button to={'#'} onClick={manageVideoButton} className={`btn svg-btn info-video video-progress`} type="video" text="Play video" />
                        <p>{JSONHelper.getSingleTextValue(media.source, 'title')}</p>
                        {media.source.legal && <p>{media.source.legal.credit_line}</p>}
                        <SeekBar id="video-seekbar" onClick={manageSeekBar} progress={videoProgress} ident="video" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default VideoType;