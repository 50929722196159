import React from 'react';

import Layout from '../layout/Layout';

const NotFound = (props) => {

    return (
        <Layout>

            <main className="not-found" role="main" aria-labelledby="headline">
                <article>
                    <h1 id="headline">Not found</h1>
                    <strong className="headline">Sorry, we can’t find the page you were looking for.</strong>

                </article>
            </main>
        </Layout>
    )
}
export default NotFound;