import React, { memo, useState } from 'react';
import BackgroundImage from '../BackgroundImage';
import ContentDetails from '../ContentDetails';

const ImageType = ({ detail, onDetailOpen, contentJson, media }) => {
    return (
        <ContentDetails contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} bottomInfo={true}>
            <BackgroundImage json={[media]} />
        </ContentDetails>
    );
};
export default memo(ImageType); 