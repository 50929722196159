import React, { useEffect, memo } from 'react';
import config from '../../../config.js';
import ContentDetails from '../ContentDetails';

const Image360Type = ({ detail, onDetailOpen, contentJson, media }) => {

    const id = 'panorama-viewer';
    const script_id = 'panorama-script';

    const checkIfSourceExist = (source) => {
        var http = new XMLHttpRequest();
        http.open('HEAD', config.getMediaURL() + source + '.js', false);
        http.send();
        return http.status !== 404;
    };

    useEffect(() => {

        const location360 = media.processed.original.location.slice(0, -4)
        const folder360 = location360.substr(location360.lastIndexOf('/') + 1)
        /*
        let source360 = location360 + '/' + folder360 + '/' + folder360 + 'data/' + folder360
        if (!checkIfSourceExist(source360)) {
            source360 = location360 + '/' + folder360 + 'data/' + folder360
        }
        if (!checkIfSourceExist(source360)) {
            source360 = location360 + '/' + folder360 + '/' + folder360 + 'data/' + folder360
        }*/

        // const source = config.getMediaURL() + source360; 
        const source = '/360/360_Jaguardata/360_Jaguar';

        if (detail) {
            const panorama_script = document.createElement('script')
            panorama_script.setAttribute('src', source + '.js');
            panorama_script.setAttribute('id', script_id);
            document.head.appendChild(panorama_script);

            panorama_script.onload = function () {
                if (window.embedpano) {
                    window.embedpano({
                        // swf: this.props.source + '.swf',
                        xml: source + '.xml',
                        target: id,
                        passQueryParameters: true
                    });
                }
            }
        }

        return () => {
            // remove script
            if (detail) {
                const element = document.getElementById(script_id);
                element.parentNode.removeChild(element);
            }

        };
    }, []);


    return (
        <ContentDetails contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} bottomInfo={true}>
            <div className="detail-media-wrap">
                <div id={id} className="content-360"></div>
            </div>
        </ContentDetails>
    );

};
export default memo(Image360Type);