import JSONHelper from './JSON';

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

export default class AppHelper {

    static getUrlFromLocation = (location) => {
        return location.pathname.substr(1);
    }

    static getFirstUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/').shift();
    }

    static getSecondUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/')[1];
    }
    static getThirthUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/')[2];
    }
    static getFourthUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/')[3];
    }

    static getContentByUrl = (contents, url) => {
        if (!contents || !contents.length) {
            return false;
        }
        let c = contents.filter(cc => cc.url === url);
        return c.length ? c[0] : false;
    }


    static connectTexts = (arr) => {
        if (arr.length === 1) return arr[0];
        const firsts = arr.slice(0, arr.length - 1);
        const last = arr[arr.length - 1];
        return firsts.join(', ') + ' and ' + last;
    }

    static getThemes = (waypointJson) => {
        return waypointJson.themes ? waypointJson.themes : [];
    }

    static getThemesData = (waypointJson) => {
        const themes = this.getThemes(waypointJson);
        if (!themes.length) {
            return [];
        }

    }

    static convertToSlug = (text) => {
        return text
            .toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-')
            ;
    }


    static getWaypointContents = (waypointJson) => {
        if (!waypointJson || !waypointJson.themes) {
            return [];
        }
        var contents = [];
        // fix sort, multiple themes
        var sort = 1;
        waypointJson.themes.forEach((t, tIndex) => {
            if (t.contents && t.contents.length) {
                t.contents.forEach((cn, index) => {
                    cn.sort = sort;
                    cn.theme = tIndex;
                    contents.push(cn);
                    sort++;
                });

                //contents = contents.concat(t.contents);

            }
        });
        return contents;
    }
}