
import AppHelper from '../../helpers/App';

import {
    SET_LOADING,
    SET_SLEEPING,
    SET_WAYPOINT,
    SET_CONTENTS,
} from '../types';

export default (state, action) => {
    switch (action.type) {

        case SET_LOADING:
            return {
                ...state,
                loaded: false
            };

        case SET_SLEEPING:
            return {
                ...state,
                sleeping: action.payload
            };

        case SET_WAYPOINT:
            return {
                ...state,
                waypoint: action.payload.data.hits.hits[0]._source,
            };

        case SET_CONTENTS:

            var contents = [];

            // get sorts 
            const wpContents = AppHelper.getWaypointContents(state.waypoint);
            let contentSorts = {};
            let contentThemes = {};
            let contentUrls = {};
            if (wpContents) {
                wpContents.forEach((cnt, index) => {
                    contentSorts[cnt.admin.id] = cnt.sort;
                    contentThemes[cnt.admin.id] = cnt.theme;
                    contentUrls[cnt.admin.id] = cnt.contentUrl;

                });
            }

            for (var c in action.payload.data.hits.hits) {
                let content = action.payload.data.hits.hits[c];
                content._source.sort = contentSorts[content._source.admin.id];
                content._source.theme = contentThemes[content._source.admin.id];
                content._source.url = contentUrls[content._source.admin.id];
                contents.push(content._source);
            }

            // sort using sort
            contents.sort((a, b) => (a.sort > b.sort) ? 1 : -1);

            return {
                ...state,
                contents: contents,
                loaded: true
            };

        default:
            return state;
    }
};