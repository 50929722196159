import React, { memo } from 'react';

// content types
import ImageType from './contentTypes/Image';
import VideoType from './contentTypes/Video';
import ZoomImageType from './contentTypes/ZoomImage';
import Image360type from './contentTypes/Image360';

const Content = ({ detail, type, contentJson, media, onDetailOpen, onActivation }) => {

    switch (type) {
        default:
        case 'image':
            return (<ImageType key={1} detail={detail} type={type} contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} />);

        case 'video':
            return (<VideoType key={2} detail={detail} type={type} contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} onActivation={onActivation} />);

        case 'zoomImage':
            return (<ZoomImageType key={3} detail={detail} type={type} contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} />);

        case '360':
        case 360:
            return (<Image360type key={4} detail={detail} type={type} contentJson={contentJson} media={media} onDetailOpen={onDetailOpen} />);
    }

};
export default memo(Content);

