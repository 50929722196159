import React, { useState, useEffect, useRef, useContext } from 'react';
import ContentListItem from './ContentListItem';

import ProgressContext from '../../context/progress/progressContext';
import Button from './Button';

// import FlipMove from 'react-flip-move';
import { SmoothScroll } from '../../helpers/SmoothScroll';

var Isotope = require('isotope-layout');
require('isotope-horizontal');

const ContentsList = ({ contents, filter }) => {

    //const filterState = useContext(filterContext); 
    const progressContext = useContext(ProgressContext);

    const [nextArrowVisible, setNextArrowVisible] = useState(true);
    const [prevArrowVisible, setPrevArrowVisible] = useState(false);
    const [isotope, setIsotope] = React.useState(null);

    var scrollBar = useRef(false);
    var timer = useRef(false);

    const scrollNext = (event) => {
        scrollBar.current.scrollTo(scrollBar.current.offset.x + 400, 0, 500);
    }

    const scrollPrev = (event) => {
        scrollBar.current.scrollTo(scrollBar.current.offset.x - 400, 0, 500);
    }

    useEffect(() => {
        if (scrollBar.current) {
            scrollBar.current.scrollTo(0, 0, 200);
        }


        if (isotope) {
            filter === "all"
                ? isotope.arrange({ filter: `.filterable` })
                : isotope.arrange({ filter: `.filter_${filter}` });
        }


    }, [isotope, filter]);

    useEffect(() => {

        scrollBar.current = SmoothScroll('.content-list');
        scrollBar.current.addListener((status) => {

            if (timer.current) {
                window.cancelAnimationFrame(timer.current);
            }

            // Setup the new requestAnimationFrame()
            timer.current = window.requestAnimationFrame(function () {

                if (status.offset.x >= status.limit.x) {
                    setNextArrowVisible(false);
                } else if (status.offset.x <= 0) {
                    setPrevArrowVisible(false);
                } else {
                    setNextArrowVisible(true);
                    setPrevArrowVisible(true);
                }

            });
        });

        setTimeout(() => {
            if (scrollBar.current.size.content.width > scrollBar.current.size.container.width) {
                setNextArrowVisible(true);
            } else {
                setNextArrowVisible(false);
            }
        }, 700);

        setIsotope(
            new Isotope(document.querySelector('.isotope-container'), {
                itemSelector: ".list-item",
                layoutMode: "horiz",
                transitionDuration: 700,
                stagger: 25,
                filter: 'none',
                hiddenStyle: {
                    opacity: 0,
                    transform: 'translateX(100vw)'
                },
                visibleStyle: {
                    opacity: 1,
                    transform: 'translateX(0)'
                }
            })
        );


    }, [contents]);

    return (
        <React.Fragment>

            <div className={`content-list dragscroll ${prevArrowVisible ? 'has-prev' : ''} ${nextArrowVisible ? 'has-next' : ''}`}>

                <div className="isotope-container">
                    {
                        contents.map((content, i) => {
                            let cls = '';
                            if (content.attractorIcon === 360) {
                                cls += 'filter_360s';
                            }

                            cls += ' filter_' + content.theme;

                            if (progressContext.visited.indexOf(content.admin.id) >= 0) {
                                cls += ' visited';
                            }

                            return (<li key={i} className={`filterable list-item ${cls}`}>
                                <ContentListItem json={content} />
                            </li>)
                        })
                    }
                </div>

            </div>

            <Button to={'#'} onClick={scrollPrev} className={`btn svg-btn slider-prev ${prevArrowVisible ? '' : 'disabled'}`} type="prev" text="Previous" />
            <Button to={'#'} onClick={scrollNext} className={`btn svg-btn slider-next ${nextArrowVisible ? '' : 'disabled'}`} type="next" text="Next" />

        </React.Fragment>
    )
};
export default ContentsList;