import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../context/app/appContext';
import filterContext from '../context/filter/filterContext';
import Layout from '../layout/Layout';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';
import Button from './components/Button';
import ThemesList from './components/ThemesList';

/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Home = (props) => {

    const appContext = useContext(AppContext);
    const filterState = useContext(filterContext);


    const [sleeping, setSleeping] = useState(true);
    const [themes, setThemes] = useState([]);


    const start = (event) => {
        setSleeping(false);
        localStorage.setItem("kiosk_plays", "1");
    }
    const filter360 = (event) => {
        filterState.setFilter("360s");
    }

    const exploreAll = (event) => {
        setSleeping(true);
        filterState.setFilter("all");
    }


    useEffect(() => {

        setThemes(AppHelper.getThemes(appContext.waypoint));
        localStorage.setItem("kiosk_plays", "0");
        return function cleanup() {
            setSleeping(true);
        };


    }, []);

    //appContext.setTransition('slide'); 
    return (
        <Layout>
            <main className={`homepage ${sleeping ? '' : 'not-sleeping'}`} role="main">
                <div className="wrap">

                    <Button to={'#'} className="btn large center start" onClick={start} text="Start" />

                    <Button to={'/contents'} className="btn large center explore text-lower" onClick={exploreAll} text="Explore All" />

                    <div className="intro">
                        <span className="headline">{JSONHelper.getSingleTextValue(appContext.waypoint, 'title').toProperCase()}</span>
                        <h1>Protective Guardian and Submarine Slayer.</h1>
                        <p>First-hand accounts and archive footage of the multifaceted flying boat. </p>

                        <div className="btns">
                            <Button to={'/contents'} className="btn b360 svg-btn" type="360" onClick={filter360} text="Show 360s" />
                        </div>
                    </div>

                    <ThemesList themes={themes} addAllLink={false} navigate={true} />

                </div>
                <video className="bg-video" autoPlay loop muted playsInline src="http://rafm.artrabbit.studio/media/spitfire.mp4" />
                <div className="overlay"></div>
            </main>
        </Layout>
    )
}
export default Home; 