import React, { useEffect } from 'react';
import AppState from './context/app/AppState';
import AudioState from './context/audio/AudioState';
import FilterState from './context/filter/FilterState';
import GalleryState from './context/gallery/GalleryState';
import ProgressState from './context/progress/ProgressState';

import AppWrapper from './AppWrapper';

import './App.scss';


// This is mostly only a problem because the console lazily evaluates the object you log. 
// You could do a shallow clone of the event object to make console.log work.
console.shallowCloneLog = function () {
    var typeString = Function.prototype.call.bind(Object.prototype.toString)
    console.log.apply(console, Array.prototype.map.call(arguments, function (x) {
        switch (typeString(x).slice(8, -1)) {
            case 'Number': case 'String': case 'Undefined': case 'Null': case 'Boolean': return x;
            case 'Array': return x.slice();
            default:
                var out = Object.create(Object.getPrototypeOf(x));
                out.constructor = x.constructor;
                for (var key in x) {
                    out[key] = x[key];
                }
                Object.defineProperty(out, 'constructor', { value: x.constructor });
                return out;
        }
    }));
}


// TODO 

function App() {

    return (
        <AppState>
            <AudioState>
                <FilterState>
                    <GalleryState>
                        <ProgressState>
                            <AppWrapper />
                        </ProgressState>
                    </GalleryState>
                </FilterState>
            </AudioState>
        </AppState >
    );
}

export default (App);
