import React, { useEffect, useContext, useRef } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import AppContext from './context/app/appContext';

import PageSwitch from './PageSwitch';
import Loading from './pages/Loading';
import Home from './pages/Home';
import AudioPlayer from './pages/components/contentTypes/AudioPlayer';

import config from './config';

// test data
//import json from './data/sites_trails.json';

// jsons - temp imports, later we will fetch data
//import homepageJSON from './data/homepage.json';
//import sectionsJSON from './data/sections.json';

function AppWrapper() {

    const appContext = useContext(AppContext);

    var timer = useRef(false);

    useEffect(() => {

        if (!appContext.waypoint) {
            appContext.getWaypoint();
        }
        if (appContext.waypoint && !appContext.contents) {
            appContext.getContents();
        }
    }, [appContext.waypoint]);

    useEffect(() => {
        // sleep

        onInactive(180000, isInactive);

    }, []);

    const isInactive = () => {


        clearTimeout(timer.current);

        let isPlaying = false
        const audioPlayer = document.getElementById('audio-player')
        if (audioPlayer && !audioPlayer.paused) {
            isPlaying = true
        }

        const activeVideo = document.querySelector('.slick-active video')
        if (activeVideo && !activeVideo.paused) {
            isPlaying = true
        }

        if (!isPlaying && localStorage.getItem("kiosk_plays") == "1") {
            //cookies.set('_ga', 'removed', { path: '/', expires: new Date(Date.now() - 1000), domain: '.rafm.tours' });
            //cookies.set('_gid', 'removed', { path: '/', expires: new Date(Date.now() - 1000), domain: '.rafm.tours' });
            localStorage.setItem("kiosk_plays", "0");
            window.location.href = config.rootDir;
        }
    }

    const onInactive = (ms, cb) => {
        timer.current = setTimeout(cb, ms);
        document.onmousemove = document.onmousemove = document.onmousewheel = document.ontouchend = document.onclick = document.focus = function () {
            clearTimeout(timer.current);
            timer.current = setTimeout(cb, ms);
        };
    }

    const isMovingRight = () => {
        let goingBack = localStorage.getItem("going_back") === '1';
        localStorage.setItem("going_back", '0');
        return goingBack;
    }

    const isFading = () => {
        return appContext.transition == 'fade';
    }

    const animationClass = () => {
        return 'fade';
        let className = '';
        if (isFading()) {
            className = 'fade';
        } else {
            className = isMovingRight() ? "slide-right" : "slide-left";
        }
        return className;
    }

    const Contents = withRouter(({ history, location, match }) => {
        return (
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    timeout={{ enter: 0, exit: 300 }}
                    classNames={'page-slide'}
                >
                    <div className={animationClass()} >
                        <Switch location={location}>
                            <Route exact path='/' component={Home} location={location} />
                            <Route component={PageSwitch} location={location} />
                        </Switch>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        );
    });

    return appContext.loaded ? (
        <div className="app-wrap">
            <BrowserRouter>
                <Contents />
            </BrowserRouter>
            <AudioPlayer />
        </div>
    ) : <Loading />;

}

export default (AppWrapper); 