import React, { useState, useEffect, useRef, useContext, memo } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import GalleryContext from '../../context/gallery/galleryContext';

import videojs from 'video.js';

import Content from './Content';

const Gallery = ({ contentJson, waypointJson, groupJson, contentUrl, history }) => {

    const galleryContext = useContext(GalleryContext);
    const [isDragging, setIsDragging] = useState(false);
    const [isUserInactive, setIsUserInactive] = useState(false);
    const [detilIsOpen, setDetilIsOpen] = useState(false);
    const [swipable, setSwipable] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);

    var timer = useRef(false);
    var carouselEl = useRef(null);

    const multimedia = [];
    if (contentJson.multimedia && contentJson.multimedia.length) {
        const onlyImages = contentJson.multimedia.filter((m, index) => {
            let mediaType = m.mediaType ? m.mediaType : 'image';
            if (mediaType !== 'audio') {
                return true;
            }
        })
        contentJson.multimedia.forEach((m, index) => {
            let mediaType = m.mediaType ? m.mediaType : 'image'; // fix for primary media 
            m.mediaType = mediaType;
            if (mediaType !== 'audio') { // skip
                if ((m['@link'] && m['@link'].role && m['@link'].role[0].value === 'content') || onlyImages.length < 2) { // skip attractor images
                    if (contentJson.multimedia.length < 3 || !m.primary) { // only skip first image if there are images
                        multimedia.push(m);
                    }
                }
            }
        });
    }

    const onInactive = (ms, cb) => {
        timer.current = setTimeout(cb, ms);
    }

    const galleryClick = (event) => {
        isActive();
    };

    const isActive = () => {
        setIsUserInactive(false);
        clearTimeout(timer.current);
        timer.current = setTimeout(isInactive, 5000);
    }

    const isInactive = () => {

        clearTimeout(timer.current);
        setIsUserInactive(true);
    }

    const onDetailOpen = (event) => {
        if (isDragging) {
            return false;
        }
        setSwipable(detilIsOpen);
        setDetilIsOpen(!detilIsOpen);
    }

    useEffect(() => {
        setIsDragging(false);
        playVideos();

        // sleep
        onInactive(5000, isInactive);

        return () => {
            pauseVideos();
            setIsDragging(false);
            clearTimeout(timer.current);
        };
    }, []);

    const beforeChange = (current, next) => {
        setIsDragging(true);
        pauseVideos();
    }

    const afterChange = (current) => {
        setIsDragging(false);
        setCurrentSlide(current);
        galleryContext.setCurrentSlide(current, contentJson.admin.id);
        playVideos();
        isActive();
    }

    // pause videos
    const pauseVideos = () => {
        const players = videojs.getPlayers();
        if (players) {
            Object.keys(players).forEach(function (key) {
                players[key].pause();
            });
        }
    };


    const playVideos = () => {

        const activeVideo = document.querySelector('.slick-active video')
        const activeAudio = document.getElementById('audio-player')

        if (activeVideo && (!activeAudio || (activeAudio && activeAudio.paused))) {

            const videoHtmlID = activeVideo.getAttribute('id')
            const videoID = videoHtmlID.replace('_html5_api', '')
            const players = videojs.getPlayers();
            if (players[videoID]) {
                players[videoID].play();
            }


            /*
            const activePlayer = pickBy(players, function (value, key, object) {
                return (value.id_ === videoID)
            })
            activePlayer[videoID].play()
            */
        }

    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 200,
        swipeToSlide: swipable,
        swipe: swipable,
        touchMove: swipable,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '100px',
        //responsive: [{ breakpoint: 1023, settings: { centerMode: false, dots: true, } }],

        customPaging: i => (
            <span>{i + 1}</span>
        ),
        //initialSlide: currentSlide, //appContext.currentSlide,
        beforeChange: (current, next) => { beforeChange(current, next) },
        afterChange: current => { afterChange(current) }
    };


    return multimedia.length > 0 ? (
        <div onClick={(event) => galleryClick(event)} className={`gallery-container ${detilIsOpen ? 'detail-open' : ''} ${isUserInactive ? 'inactive' : ''} ${multimedia.length > 1 ? '' : 'no-slides'}`}>

            <Slider ref={slider => (carouselEl = slider)} {...settings}>
                {multimedia.map((m, i) => {
                    return (<Content detail={true} type={m.mediaType} contentJson={contentJson} media={m} onActivation={galleryClick} onDetailOpen={onDetailOpen} key={i} />);
                })}
            </Slider>

            { /* hasDetail.includes(multimedia[currentSlide].mediaType) &&  <p>has</p> */}

        </div>
    ) : '';
};
export default memo(Gallery);
