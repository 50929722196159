import React, { useContext } from 'react';

import AppContext from './context/app/appContext';

import AppHelper from './helpers/App';

import Content from './pages/Content';
import Contents from './pages/Contents';
import NotFound from './pages/NotFound';


function PageSwitch(props) {

    const appContext = useContext(AppContext);

    const pageTypes = {
        notfound: NotFound,
        contents: Contents,
        content: Content,
    };

    // is waypoint ?   ..
    // otherwise not found
    const getPageTypeByUrl = (url) => {

        let parts = url.substr(1).split('/');

        var first = parts[0],
            second = parts[1],
            thirth = parts[2];

        // pages variations 

        if (parts.length == 1) {
            return pageTypes.contents;
        }

        if (parts.length == 2) { // content or question detail 
            return pageTypes.content;
        }

        return pageTypes.notfound;
    }

    // find out what to show ?!?!
    const currentUrl = props.location.pathname;
    const PageToRender = getPageTypeByUrl(currentUrl);

    //console.log(appContext);

    return <PageToRender {...props} />;

}

export default (PageSwitch); 