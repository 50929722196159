import React, { useEffect } from 'react';

const SeekBar = ({ id, progress, onClick, ident = '' }) => {

    useEffect(() => {

    }, []);

    const clicked = (event) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <div id={id} onClick={(event) => clicked(event)} className={`seekbar ${ident}`}><span className="bg" style={{ width: (progress) + "%" }}><span className="handle"></span></span></div >
    )
};
export default SeekBar;
