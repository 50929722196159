import React, { useReducer } from 'react';
import FilterContext from './filterContext';
import FilterReducer from './filterReducer';

import {
    SET_FILTER
} from '../types';

const FilterState = props => {
    const initialState = {
        filter: 'all'
    }

    const [state, dispatch] = useReducer(FilterReducer, initialState);

    // set content filter 
    const setFilter = filter => dispatch({ type: SET_FILTER, payload: filter });

    return <FilterContext.Provider
        value={{
            setFilter: setFilter,
            filter: state.filter
        }}>
        {props.children}
    </FilterContext.Provider>
};

export default FilterState;